import store from '../store';
//import moment from 'moment';
import Enumerable from "linq";

class StepTracker {
     registerStep(stepNumber, message) {

        var stepTracker = store.state.stepTracker;
        if (stepTracker == null)
            stepTracker = [];

        var st = {
            "n": parseInt(stepNumber),
            "m": message,
            "ts": Date.now() / 1000
        };
        stepTracker.push(st)
        store.commit("saveStepTracker", stepTracker);
    }

     returnStep(stepNumber) {
        var stepTracker = store.state.stepTracker;

        if (stepTracker == null)
            stepTracker = [];

        var step = Enumerable.From(stepTracker).OrderByDescending("$.ts")
             .Where("$.n == " + parseInt(stepNumber) + "").FirstOrDefault();

         if (step === undefined)
             return null;

        return step;
    }
}

export default new StepTracker();