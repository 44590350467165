<template>
    <transition name="slide-up">
        <div class="content-container content-half multiselect-container " v-if="isActive">
            <div class="padding-div">
                <div class="content-title">
                    <div class="fs-32px fw-700 color-dark mb-4">Chegou a hora de escolher seu Perfil de Investimento. Com base em suas respostas, selecionamos dentre as opções disponíveis a mais adequada para você:</div>
                    <div class="mt-5">
                        <div class="multiselect-group">
                            <div class="multiselect-row pointer" @click="select(1)">
                                <div v-bind:class="(selection == 1 ? 'multiselect active' : 'multiselect')">
                                    <!--<p class="mb-0"><span class="letter">A</span>Prevaler Base</p>-->
                                    <Question letter="A" text="Prevaler Base"></Question>
                                    <v-icon right v-if="selection == 1">check</v-icon>
                                </div>
                                <div class="out-icon" v-if="showTips">
                                    <div class="valia-tooltip-no-text-decoration">
                                        <v-icon style="color:#0972D2;display:block;">information</v-icon>
                                        <span class="valia-tooltiptext">Perfil Base – É o de menor risco dentre os perfis oferecidos. As aplicações são feitas apenas em renda fixa e buscam seguir o índice CDI.</span>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="multiselect-row pointer" @click="select(2)">
                                <div v-bind:class="(selection == 2 ? 'multiselect active' : 'multiselect')">
                                    <!--<p class="mb-0"><span class="letter">B</span>Prevaler Moderado</p>-->
                                    <Question letter="B" text="Prevaler Moderado"></Question>
                                    <v-icon right v-if="selection == 2">check</v-icon>
                                </div>
                                <div class="out-icon" v-if="showTips">
                                    <div class="valia-tooltip-no-text-decoration">
                                        <v-icon style="color:#0972D2;display:block;">information</v-icon>
                                        <span class="valia-tooltiptext">Perfil Moderado – Apresenta risco maior que o Base e menor que o Multi, com Investimentos em renda fixa e fundos multimercado.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="multiselect-row pointer" @click="select(3)">
                                <div v-bind:class="(selection == 3 ? 'multiselect active' : 'multiselect')">
                                    <!--<p class="mb-0"><span class="letter">C</span>Prevaler Multi</p>-->
                                    <Question letter="C" text="Prevaler Multi"></Question>
                                    <v-icon right v-if="selection == 3">check</v-icon>
                                </div>
                                <div class="out-icon" v-if="showTips">
                                    <div class="valia-tooltip-no-text-decoration">
                                        <v-icon style="color:#0972D2;display:block;">information</v-icon>
                                        <span class="valia-tooltiptext">Perfil Multi – Neste perfil, aproximadamente 20% dos seus recursos serão investidos em renda variável (Brasil e exterior) e 80% em um conjunto formado por renda fixa e fundos multimercado. Esta composição faz com que a rentabilidade oscile razoavelmente de um mês para outro.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="multiselect-row pointer" @click="select(4)">
                                <div v-bind:class="(selection == 4 ? 'multiselect active' : 'multiselect')">
                                    <!--<p class="mb-0"><span class="letter">D</span>Prevaler Ative</p>-->
                                    <Question letter="D" text="Prevaler Ative"></Question>
                                    <v-icon right v-if="selection == 4">check</v-icon>
                                </div>
                                <div class="out-icon" v-if="showTips">
                                    <div class="valia-tooltip-no-text-decoration">
                                        <v-icon style="color:#0972D2;display:block;">information</v-icon>
                                        <span class="valia-tooltiptext">
                                            Perfil Ative - Tem aproximadamente 40% da carteira investida em renda variável (Brasil e exterior) e 60% em um conjunto formado por renda fixa e fundos multimercado.
                                            Esta composição faz com que a rentabilidade também oscile razoavelmente de um mês para outro, mais que no Multi.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="multiselect-row pointer" @click="select(5)">
                                <div v-bind:class="(selection == 5 ? 'multiselect active' : 'multiselect')">
                                    <!--<p class="mb-0"><span class="letter">E</span>Prevaler Intense</p>-->
                                    <Question letter="E" text="Prevaler Intense"></Question>
                                    <v-icon right v-if="selection == 5">check</v-icon>
                                </div>
                                <div class="out-icon" v-if="showTips">
                                    <div class="valia-tooltip-no-text-decoration">
                                        <v-icon style="color:#0972D2;display:block;">information</v-icon>
                                        <span class="valia-tooltiptext">
                                            Perfil Intense - Tem aproximadamente 60% da carteira investida em renda variável (Brasil e exterior) e 40% em um conjunto formado por renda fixa e fundos multimercado.
                                            Esta composição faz com que a rentabilidade também oscile razoavelmente de um mês para outro, mais que no Ative.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-buttons">
                    <div class="d-flex justify-content-end">
                        <button v-on:click="next" class="button-primary-bg-primary">
                            Avançar
                            <v-icon right>east</v-icon>
                        </button>
                    </div>
                    <div class="d-flex justify-content-end display-sm-none">
                        <div class="fs-13px mt-3 display-sm-none">Declaro que li, compreendi e aceitei as regras, termos e condições do <a href="https://adesao.valia.com.br/Content/docs/Regulamento_Perfil_PREVALER_FINAL.PDF" target="_blank">Regulamento</a> para o exercício da opção e alteração da opção de investimento por meio eletrônico.</div>
                    </div>
                    <button type="button" class="btn fill-after hide" v-show="false" data-bs-toggle="modal" ref="popBtn" data-bs-target="#modalPreenchimentoQuestionarioDiferenteSugestao">
                        Perfil diferente
                    </button>
                </div>
            </div>
        </div>

    </transition>
</template>
<script>
    //import 'bootstrap';
    import { mapState } from "vuex";
    import Question from '../../components/Question.vue';
    import Profile from '../../services/profile/Profile';
    export default {
        name: 's04-preencheranalise-7',
        props: ['perfilInvestidor', 'visible'],
        computed: {
            ...mapState(["questionario"]),
        },
        components: {
            Question
        },
        data: () => ({
            isActive: false,
            loading: false,
            selection: 0,
            suggestion: "34",
            showTips: true,
            showBaseTip: false,
        }),
        watch: {
            visible: function () {
                this.toggle();
            }
        },
        methods: {
            toggle() {
                this.isActive = !this.isActive;

                if (this.isActive)
                    this.loadPerfil();
            },
            select: function (val) {
                this.selection = val;
                this.$emit('selectperfilquiz', null, this.selection, this.suggestion);
                //}
            },
            next: function () {
                var tempSelectionValue = "";

                if (this.selection == 1)
                    tempSelectionValue = "34";
                if (this.selection == 2)
                    tempSelectionValue = "32";
                if (this.selection == 3)
                    tempSelectionValue = "31";
                if (this.selection == 4)
                    tempSelectionValue = "33";
                if (this.selection == 5)
                    tempSelectionValue = "55";

                this.$emit('selectperfilquiz', null, this.selection, this.suggestion);

                if (tempSelectionValue != this.suggestion) {
                    this.$refs.popBtn.click()
                }
                else {
                    this.$emit('next');
                }
            },
            back: function () {
                this.$emit('back');
            }, 
            loadPerfil: async function () {
                if (this.perfilInvestidor !== undefined && (this.perfilInvestidor.questao01 === undefined && this.perfilInvestidor.questao02 === undefined && this.perfilInvestidor.questao03 === undefined && this.perfilInvestidor.questao04 === undefined && this.perfilInvestidor.questao05 === undefined)) {
                    this.perfilInvestidor.perfilSugerido = this.suggestion;
                    this.perfilInvestidor.perfilSelecionado = this.perfilInvestidor.perfilSugerido;
                    this.showBaseTip = true;
                }
                else {
                    var r = await Profile.calcPerfil(this.perfilInvestidor);
                    this.perfilInvestidor.perfilSugerido = r;
                    this.perfilInvestidor.perfilSelecionado = r;
                    this.showBaseTip = false;
                    this.suggestion = this.perfilInvestidor.perfilSugerido;
                }

                if (this.perfilInvestidor.perfilSelecionado == "34")
                    this.selection = 1;
                if (this.perfilInvestidor.perfilSelecionado == "32")
                    this.selection = 2;
                if (this.perfilInvestidor.perfilSelecionado == "31")
                    this.selection = 3;
                if (this.perfilInvestidor.perfilSelecionado == "33")
                    this.selection = 4;
                if (this.perfilInvestidor.perfilSelecionado == "55")
                    this.selection = 5;
            }
        },

        mounted() {

        },
    };
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

