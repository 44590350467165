<template>
    <div class="header">
            <v-row>
                <v-col col="12" class="d-flex align-items-center">
                    <img src="/img/logo-prevaler.png" />
                    <v-divider vertical color="black"></v-divider>
                    <img src="/img/logo-valia.png" />
                </v-col>
            </v-row>
    </div>
</template>

<script> 
    import 'bootstrap';
    export default {
        name: 'Header',
        props: {

        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

