import httpIPIFY from "../http-ipify";

class IPIFYAPI {
     getIPAddress() {
        return  httpIPIFY.get(`/?format=json&callback=?`);
  } 
}

export default new IPIFYAPI();

 