import http from "../http-common";

class EmailAPI {
    async postMensagens(valor, destinatario) {
        var destinatarios = [];
        destinatarios.push(destinatario);

        valor.destinatarios = destinatarios;

        //console.log({ response: { data: valor } });

        return await http.post(`/email/v1/mensagens`,valor);
  }
}

export default new EmailAPI();