import http from "../http-common";

class AtendimentoAPI {
    async postPessoas(CPF, idAtendPadrao, valor) {
        return await http.post(`/atendimento/v1/pessoa/${CPF}/atendimento_padrao/${idAtendPadrao}`, CPF, idAtendPadrao, valor);
    }

    async postSessoes() {
        return await http.post(`/atendimento/v2/sessoes`);
    }

    async postAtendimentoPadrao(valor, token) {

        return await http.post(`/atendimento/v2/pessoa/atendimentos_padrao`, valor,
            {
                headers: {
                    "Api-Token": token.toString()
                }
            });
    }

    async getPessoas(cpf, matricula) {
        return await http.get(`/atendimento/v1/pessoas?cpf=${cpf}&matricula=${matricula}`, cpf, matricula);
    }
        async postPessoa(token, valor) {

        return await http.post(`/atendimento/v2/pessoas`, valor,
            {
                headers: {
                    "Api-Token": token.toString()
                }
            });
    }
}

export default new AtendimentoAPI();
