<template>
    <div class="bg-image bg-escolhas display-sm-none">
        <img src="/img/bg-1.png" />
    </div>
</template>

<script>
    import 'bootstrap';
    export default {
        name: 'BgEscolhas',
        props: {
         
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

