import http from "../http-common";

class SMSAPI {
	async postMensagens(ddi, ddd, telefone, mensagem) {
		const payload = {
			"ddi": ddi,
			"ddd": ddd,
			"telefone": telefone,
			"mensagem": mensagem
		};
		//console.log({ response: { data: payload } });
		await http.post(`/sms/v1/mensagens`, payload);
 

  }
}

export default new SMSAPI();