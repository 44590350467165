import http from "../http-common";

class CorporativoAPI {

     
    async getPessoasUnificado(cpf,  token) {
        return await http({
            method: 'get',
            url: `/corporativo/v1/pessoas/unificado?CPF=` + cpf,
            headers: {
                "Token": token
            }
        });
    }

    async getEmpresasDescontoFolha(empresaId) {
        return await http.get(`/corporativo/v1/empresas/${empresaId}/descontos`, empresaId);
    }
    async getGrausParentesco() {
        return await http.get(`corporativo/v1/grausParentesco`);
    }

    async getTiposTelefone() {
        return await http.get(`corporativo/v1/telefone`);
    }

    async getEstadosCivis() {
        return await http.get(`corporativo/v1/estadosCivis`);
    }

    async getTiposDocumentoID() {
        return await http.get(`corporativo/v1/tiposDocumentoID`);
    }

    async getTiposRepresentacao() {
        return await http.get(`corporativo/v1/tiposrepresentacao`);
    }
    async getOcupacoesProfissional() {
        return await http.get(`corporativo/v1/ocupacoesProfissional`);
    }
    async getEmpresasHabilitadasDescontoFolha() {
        return await http.get(`corporativo/v1/empresasHabilitadasDescontoFolha`);
    }

    async getFaixaSalarialAll() {
        var mes = new Date().getMonth() + 1;
        var ano = new Date().getFullYear();
        return await http.get(`corporativo/v1/faixasSalarial?Ano=` + ano + `&Mes=` + mes);
    }

    async getFaixaSalarial(idEmpresa, registro) {
        return await http.get(`/corporativo/v1/pessoas/${idEmpresa}/${registro}/faixassalarial`, idEmpresa, registro);
    }

    //async getDetalhesDependente(idEmpresa, registro, idDependente) {
    //    return await http.get(`/corporativo/v1/pessoas/${idEmpresa}/${registro}/dependentes/${idDependente}/detalhes`, idEmpresa, registro, idDependente);
    //} 

    //async getCanais(idEmpresa, registro, recurso, idRecurso) {
    //    return await http.get(`/corporativo/v1/pessoas/${idEmpresa}/${registro}/${recurso}/${idRecurso}/canais`, idEmpresa, registro, recurso, idRecurso);
    //}



    async getPessoas(cpf, tipo, token) {
        return await http({
            method: 'get',
            url: `/corporativo/v1/pessoas?CPF=` + cpf + `&tipo=` + tipo,
            headers: {
                "Token": token
            }
        });
    }
    async getDetalhesParticipante(idEmpresa, registro, matricula, token) {
        return await http({
            method: 'get',
            url: `/corporativo/v1/pessoas/` + idEmpresa + `/` + registro + `/participantes/` + matricula + `/detalhes`,
            headers: {
                "Token": token
            }
        });
    }

    async getDetalhesRepresentante(idEmpresa, registro, idRepresentante, token) {
        return await http({
            method: 'get',
            url: `/corporativo/v1/pessoas/` + idEmpresa + `/` + registro + `/representantes/` + idRepresentante + `/detalhes`,
            headers: {
                "Token": token
            }
        });
    }

    async getDetalhesDependente(idEmpresa, registro, idDependente, token) {
        return await http({
            method: 'get',
            url: `/corporativo/v1/pessoas/` + idEmpresa + `/` + registro + `/dependentes/` + idDependente + `/detalhes`,
            headers: {
                "Token": token
            }
        });
    }

    async getDependentesAllDetalhado(idEmpresa, registro) {
        //return await http.get(`/corporativo/v1/pessoas/${idEmpresa}/${registro}/dependentes`, idEmpresa, registro);
        return await http.get(`/corporativo/v1/dependentes?empresa=${idEmpresa}&registro=${registro}`, idEmpresa, registro);
    }

}

export default new CorporativoAPI();

