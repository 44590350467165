import moment from 'moment';

import store from '../../store';
class Profile {
    async skipquestionario(questionario, perfilInvestidor) {
        questionario.questao01 = 0;
        questionario.questao02 = 0;
        questionario.questao03 = 0;
        questionario.questao04 = 0;
        questionario.questao05 = 0;
        questionario.questao06 = 0;

        perfilInvestidor.questao01 = "-1";
        perfilInvestidor.questao02 = "-1";
        perfilInvestidor.questao03 = "-1";
        perfilInvestidor.questao04 = "-1";
        perfilInvestidor.questao05 = "-1";
        perfilInvestidor.questao06 = "-1";
        perfilInvestidor.perfilSugerido = ""; // "34";
        perfilInvestidor.perfilSelecionado = "34";
        perfilInvestidor.textoAtendimento = "Participante se recusou a responder o questionário e concordou em ser colocado no perfil Prevaler Base"
        perfilInvestidor.recusa = "S";
        perfilInvestidor["STATUS_RESPOSTA_SUITABILITY"] = "R";
        perfilInvestidor["PERGUNTAS_RESPOSTAS"] = "";
        perfilInvestidor["DATA_RESP_SUITABILITY"] = moment(new Date(), "DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");


        store.commit("saveQuestionario", questionario);
    }

    async selectperfilquiz(perfilSelecionado, perfilSugerido, perfilInvestidor, questionario) {
        perfilInvestidor.perfilSugerido = perfilSugerido;

        if (perfilSelecionado == 1)
            perfilInvestidor.perfilSelecionado = "34";
        if (perfilSelecionado == 2)
            perfilInvestidor.perfilSelecionado = "32";
        if (perfilSelecionado == 3)
            perfilInvestidor.perfilSelecionado = "31";
        if (perfilSelecionado == 4)
            perfilInvestidor.perfilSelecionado = "33";
        if (perfilSelecionado == 5)
            perfilInvestidor.perfilSelecionado = "55";

        perfilInvestidor.textoAtendimento = null;
        perfilInvestidor.recusa = "N";
        perfilInvestidor["STATUS_RESPOSTA_SUITABILITY"] = "P";


        var pr = "";
        pr += ("1-" + (this.numeroParaLetra(questionario.questao01)));
        pr += (", 2-" + (this.numeroParaLetra(questionario.questao02)));
        pr += (", 3-" + (this.numeroParaLetra(questionario.questao03)));
        pr += (", 4-" + (this.numeroParaLetra(questionario.questao04)));
        pr += (", 5-" + (this.numeroParaLetra(questionario.questao05)));
        pr += (", 6-" + (this.numeroParaLetra(questionario.questao06)));

        perfilInvestidor["PERGUNTAS_RESPOSTAS"] = pr;
        perfilInvestidor["DATA_RESP_SUITABILITY"] = moment(new Date(), "DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");

    }

    numeroParaLetra(numero) {
        switch (parseInt(numero)) {
            case 1:
                return 'a';
            case 2:
                return 'b';
            case 3:
                return 'c';
            case 4:
                return 'd';
            case 5:
                return 'e';
            default:
                return 'N�mero inv�lido';
        }
    }

    numeroQuestaoParaTexto(questao) {
        switch (parseInt(questao)) {
            case 1:
                return 'Em quanto tempo pretende usar o benefício?';
            case 2:
                return 'Quanto você sabe sobre o mercado financeiro?';
            case 3:
                return 'Qual das frases abaixo melhor se aplica a você em relação aos recursos investidos para o objetivo definido em questão?';
            case 4:
                return 'Ao investir R$ 100,00 você prefere:';
            case 5:
                return 'Se seu investimento de R$ 100,00 cai para R$ 80,00, o que você faz?';
            case 6:
                return 'Em sua opinião, o que melhor define o investimento para cumprir seu objetivo?';
        }
    }

    numeroQuestaoERespostaParaTextoResposta(questao, resposta) {
        switch (parseInt(questao)) {
            case 1:
                switch (resposta) {
                    case 1:
                        return "Em até 5 anos";
                    case 2:
                        return "Entre 5 e 10 anos";
                    case 3:
                        return "Acima de 10 anos";
                }
                break;
            case 2:
                switch (resposta) {
                    case 1:
                        return "Pouco";
                    case 2:
                        return "Mais ou menos";
                    case 3:
                        return "Muito";
                }
                break;
            case 3:
                switch (resposta) {
                    case 1:
                        return "Avalio constantemente os resultados, e me preocupo com oscilações negativas";
                    case 2:
                        return "Não penso nesses recursos no curto prazo, mas rentabilidades mensais baixas me incomodam";
                    case 3:
                        return "Avalio o que está acontecendo, mas mantenho foco no longo prazo";
                    case 4:
                        return "Não me preocupo com eles, uma vez que só vou utilizar tais recursos daqui a muitos anos";
                }
                break;
            case 4:
                switch (resposta) {
                    case 1:
                        return "Em um ano, ter R$ 110,00";
                    case 2:
                        return "Em um ano, poder ter entre R$ 90,00 e R$ 120,00";
                    case 3:
                        return "Em um ano, poder ter entre R$ 80,00 e R$ 150,00";
                }
                break;
            case 5:
                switch (resposta) {
                    case 1:
                        return "Vendo imediatamente";
                    case 2:
                        return "Busco entender o que aconteceu para decidir se vendo ou mantenho";
                    case 3:
                        return "Busco investir mais recursos a um preço melhor";
                }
                break;
            case 6:
                switch (resposta) {
                    case 1:
                        return "Segurança e preservação de capital";
                    case 2:
                        return "Bons rendimentos, com baixo risco de perdas permanentes";
                    case 3:
                        return "Foco em prazos maiores e possíveis oscilações de curto prazo.";
                }
                break;
        }
    }

    async doselectquiz(evt, questao, valor, questionario, perfilInvestidor) {
        switch (questao) {
            case "01":
                switch (valor) {
                    case 1:
                        perfilInvestidor.questao01 = "0";
                        break;
                    case 2:
                        perfilInvestidor.questao01 = "5";
                        break;
                    case 3:
                        perfilInvestidor.questao01 = "10";
                        break;
                }
                questionario.questao01 = valor;

                break;
            case "02":
                switch (valor) {
                    case 1:
                        perfilInvestidor.questao02 = "0";
                        break;
                    case 2:
                        perfilInvestidor.questao02 = "5";
                        break;
                    case 3:
                        perfilInvestidor.questao02 = "10";
                        break;
                }
                questionario.questao02 = valor;

                break;
            case "03":
                switch (valor) {
                    case 1:
                        perfilInvestidor.questao03 = "0";
                        break;
                    case 2:
                        perfilInvestidor.questao03 = "3";
                        break;
                    case 3:
                        perfilInvestidor.questao03 = "8";
                        break;
                    case 4:
                        perfilInvestidor.questao03 = "10";
                        break;
                }
                questionario.questao03 = valor;

                break;
            case "04":
                switch (valor) {
                    case 1:
                        perfilInvestidor.questao04 = "0";
                        break;
                    case 2:
                        perfilInvestidor.questao04 = "5";
                        break;
                    case 3:
                        perfilInvestidor.questao04 = "10";
                        break;
                }
                questionario.questao04 = valor;

                break;
            case "05":
                switch (valor) {
                    case 1:
                        perfilInvestidor.questao05 = "0";
                        break;
                    case 2:
                        perfilInvestidor.questao05 = "5";
                        break;
                    case 3:
                        perfilInvestidor.questao05 = "10";
                        break;
                }
                questionario.questao05 = valor;

                break;
            case "06":
                switch (valor) {
                    case 1:
                        perfilInvestidor.questao06 = "0";
                        break;
                    case 2:
                        perfilInvestidor.questao06 = "5";
                        break;
                    case 3:
                        perfilInvestidor.questao06 = "10";
                        break;
                }
                questionario.questao06 = valor;

                break;
        }
        store.commit("saveQuestionario", questionario);
    }

    async calcPerfil(perfilInvestidor) {

        var points = 0;
        if (perfilInvestidor.questao01 != undefined)
            points += parseInt(perfilInvestidor.questao01);
        if (perfilInvestidor.questao02 != undefined)
            points += parseInt(perfilInvestidor.questao02);
        if (perfilInvestidor.questao03 != undefined)
            points += parseInt(perfilInvestidor.questao03);
        if (perfilInvestidor.questao04 != undefined)
            points += parseInt(perfilInvestidor.questao04);
        if (perfilInvestidor.questao05 != undefined)
            points += parseInt(perfilInvestidor.questao05);
        if (perfilInvestidor.questao06 != undefined)
            points += parseInt(perfilInvestidor.questao06);
             
        if (points <= 5) {
            return "34";
        }
        if (points > 5 && points <= 23) {
            return "32";
        }
        if (points > 23 && points <= 40) {
            return "31";
        }
        if (points > 40 && points <= 54) {
            return "33";
        }
        if (points > 54 && points <= 60) {
            return "55";
        }

        return "34";
    }

    perfilNumeroParaTexto(numero) {
        if (numero == "34")
            return "Prevaler Base";
        if (numero == "32")
            return "Prevaler Moderado";
        if (numero == "31")
            return "Prevaler Multi";
        if (numero == "33")
            return "Prevaler Ative";
        if (numero == "55")
            return "Prevaler Intense";

        return "Prevaler Base";
    }
}

export default new Profile();