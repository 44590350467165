import http from "../http-common";
import moment from 'moment';
import constants from '../constants'

class PlanoFamiliaAPI {
    async getPessoasAll(cpf, protocolo) {
        //TODO:aguandando verificacao do fabio para este caso. (sempre devemos receber um cpf e um protocolo)
        if (cpf == null || cpf == "" || cpf == "0" || cpf == 0)
            return await http.get(`/plano-familia/v1/pessoas?protocolo=${protocolo}`, protocolo);
        else if (protocolo == null || protocolo == "" || protocolo == "0" || protocolo == 0)
            return await http.get(`/plano-familia/v1/pessoas?cpf=${cpf}`, cpf);
        else
            return await http.get(`/plano-familia/v1/pessoas?cpf=${cpf}&protocolo=${protocolo}`, cpf, protocolo);
    } 

    async getPessoas(id, cpf) {
        return await http.get(`plano-familia/v1/pessoas/${id}?cpf=${cpf}`, id, cpf);
    }

    async postPessoas(valor) {
        return await http.post(`/plano-familia/v1/pessoas`, valor);
    }


    async putPessoas(id, valor) {
        valor.detalhesDaAdesao.ultimaAtualizacao = moment(new Date(), "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY HH:mm:ss");
        return await http.put(`/plano-familia/v1/pessoas/` + id, valor);
    }

    async getDescontosFolha(cpf, matricula, cpfPatrocinado, matriculaPatrocinado) {
        return await http.get(`/plano-familia/v1/descontos?cpf=${cpf}&matricula=${matricula}&cpfPatrocinado=${cpfPatrocinado}&matriculaPatrocinado=${matriculaPatrocinado}`, cpf, matricula, cpfPatrocinado, matriculaPatrocinado);
    }

    async getPatrocinados(tipo, identificador) {
        return await http.get(`/plano-familia/v1/patrocinados/${tipo}/${identificador}`, tipo, identificador);
    }

    async postAnexos(valor) {
        return await http.post(`/plano-familia/v1/Adesao/IncluirAnexo`, valor);
    }

    async deleteAnexos(valor) {
        return await http.delete(`/plano-familia/v1/Adesao/ExcluirAnexo`, {
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": constants.apiMKey
            }, data:valor });
    }

    async postArquivosPedidosFiliacao(cpf, protocolo, token, ip) {
        if (token === undefined || token == null || token == "")
            token = null;

        if (ip === undefined || ip == null || ip == "")
            ip = null;

        var pl = {
            cpf: cpf, protocolo: protocolo, "TokenAdesao": token,
            "IpAcesso": ip
        }
        console.log(pl);
        return await http.post(`/plano-familia/v1/arquivos/pedidosfiliacao`, pl);
    }


    async postArquivosTermosAdesao(cpf, protocolo, token, ip) {

        if (token === undefined || token == null || token == "")
            token = null;

        if (ip === undefined || ip == null || ip == "")
            ip = null;

        var pl = {
            cpf: cpf, protocolo: protocolo, "TokenAdesao": token,
            "IpAcesso": ip
        }
        console.log(pl);
        return await http.post(`/plano-familia/v1/arquivos/termosadesao`, pl);
    }

}

export default new PlanoFamiliaAPI();