<template>
    <transition name="slide-up">
        <div class="content-container content-half" v-if="isActive">
            <v-form ref="form">
                <div class="flex-column">
                    <div class="d-flex">
                        <!--<div class="question-number">10-</div>-->
                        <div class="flex-column padding-div">
                            <div class="fs-32px fw-700 color-dark" v-if="fluxo=='menor18' || fluxo=='convidadomenor18' || fluxo=='aberturamenor18'">
                                Com quantos anos de idade o dependente deseja usar o benefício?
                                <div class="fs-22px fw-500 color-gray">
                                    Informe uma idade estimada.<br />
                                </div>
                            </div>
                            <div class="fs-32px fw-700 color-dark" v-else>
                                Com quantos anos de idade você deseja usar o benefício?
                                <div class="fs-22px fw-500 color-gray">
                                    Informe uma idade estimada.<br />
                                </div>
                                <div class="fs-18px fw-500 color-gray" v-if="fluxo=='proprio'">
                                    Você poderá permanecer no Plano o tempo que desejar, mesmo sendo um período diferente ao informado nesse campo.<br />
                                    Para aproveitar a melhor rentabilidade, o recomendável é que a pessoa permaneça no plano pelo maior tempo possível.
                                </div>
                            </div>

                            <!--<div class="fs-18px fw-500 color-gray" v-if="fluxo=='menor18'">
                                Ele poderá permanecer no Plano o tempo que desejar, mesmo sendo um período diferente ao informado nesse campo.<br />
                                Para aproveitar a melhor rentabilidade, o recomendável é que a pessoa permaneça no plano pelo maior tempo possível.
                            </div>-->


                            <div class="single-input">

                                <v-text-field-money v-model="idadeBeneficio" class="input-caps" required :rules="[rules.required, rules.idade]" placeholder="Digite sua resposta aqui" @keyup.native.enter="next" v-on:keyup.enter="next"
                                                    v-bind:properties="{
        readonly: false,
        disabled: false,
        outlined: false,
        clearable: true,
        placeholder: ' ',
      }"
                                                    v-bind:options="{
        locale: 'pt-BR',
        length: 3,
        precision: 0,
        empty: null,
      }" />
                            </div>

                        </div>
                    </div>
                </div>
                <div class="content-buttons">
                    <div class="d-flex justify-content-end">
                        <button v-on:click="next" class="button-primary-bg-primary action-button">
                            Avançar
                            <v-icon right>east</v-icon>
                        </button>
                    </div>
                    <div class="d-flex justify-content-end display-sm-none">
                        <div class="fs-13px mt-3 display-sm-none">ou aperte <span class="fw-700">Enter</span></div>
                    </div>
                </div>

                <!--<div class="content-buttons">
                    <div class="d-flex justify-content-end">
                        <button @click="cadastrarBeneficiarioNao" type="button" class="btn fill-after">
                            Não responder
                        </button>
                    </div>
                </div>-->
            </v-form>
        </div>
    </transition>
</template>

<script>
    import 'bootstrap';
    import moment from 'moment';
    import Decimal from "../../components/Decimal.vue";
    export default {
        name: 's08-idade-beneficio',
        props: ['pessoa', 'visible', 'fluxo'],
        components: {
            "v-text-field-money": Decimal,
        },
        data: () => ({
            isActive: false,
            loading: false,
            selection: 1,
            step: 1,
            idadeBeneficio: null, //0,
            hasErrors: false,
            rules: {
            },
        }),
        watch: {
            visible: function () {
                this.toggle();
            }
        },
        methods: {
            toggle() {
                if (this.pessoa !== null && this.pessoa["perfil-plano"] != null && this.pessoa["perfil-plano"]["idade-aposentadoria"] != null) {
                    var v = this.pessoa["perfil-plano"]["idade-aposentadoria"]
                    this.idadeBeneficio = v;
                }
                this.isActive = !this.isActive;
            },
            next: function (event) {
                event.preventDefault();



                this.rules = {
                    required: value => !!value || 'Campo obrigatório.',
                    idade: value => {
                        if (value !== undefined && value !== null && value != "") {
                            var idadeMinima = 50;
                            if (parseInt(value) > 100) {
                                return "Idade inválida";
                            }

                            var date = moment(this.pessoa.dataNascimento, "DD/MM/YYYY");

                            var age = this.calculate_age(date.toDate());
                            console.log("age");
                            console.log(age);
                            if (age > 13) {
                                idadeMinima = age + 5;
                                if (value < idadeMinima)
                                    return "A idade mínima informada para utilizar o benefício deve ser de, no mínimo, " + idadeMinima + " anos.";
                            }
                            else {
                                idadeMinima = 18;
                                if (value < idadeMinima)
                                    return "A idade mínima informada para utilizar o benefício deve ser de, no mínimo, " + idadeMinima + " anos.";
                            } 
                        }
                        else {
                            return "Idade inválida";
                        }
                    },
                };

                this.$nextTick(() => {
                    if (this.$refs.form.validate()) {
                        var v = this.idadeBeneficio;
                        this.pessoa["perfil-plano"]["idade-aposentadoria"] = parseInt(v).toString();
                        this.$emit('next');
                    }
                }) 

            },
            back: function () {
                this.$emit('back');
            },
            cadastrarBeneficiarioNao: function () {
                this.$emit('cadastrarBeneficiarioNao');
            },
            calculate_age: function (dob) {
                var diff_ms = Date.now() - dob.getTime();
                var age_dt = new Date(diff_ms);

                return Math.abs(age_dt.getUTCFullYear() - 1970);
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

