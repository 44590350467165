import axios from "axios";

export default axios.create({
    baseURL: "https://api.ipify.org",
    timeout: 36000,
    credentials: 'same-origin',
    headers: {
       
    }
});
 