<template>
    <transition name="slide-up">
        <div class="content-container content-half" v-if="isActive">
            <v-form ref="form">
                <div class="flex-column">
                    <div class="d-flex">
                        <!--<div class="question-number">7-</div>-->
                        <div class="flex-column padding-div">
                            <div class="fs-32px fw-700 color-dark" v-if="fluxo=='menor18' || fluxo=='convidadomenor18' || fluxo=='aberturamenor18'">Agora iremos enviar um SMS para finalizar a adesão de {{getPessoa.nome.split(" ")[0].initCap()}}. Você pode confirmar o seu número de celular?</div>
                            <div class="fs-32px fw-700 color-dark" v-else>Agora iremos enviar um SMS para finalizar a sua adesão. Você pode confirmar o seu número de celular?</div>
                            <form class="n-form">
                                <div class="single-input">
                                    <v-text-field-simplemask v-model="value" class="input-caps" required :rules="[rules.required, rules.celular]"
                                                             v-bind:properties="{
        prefix: '',
        suffix: '',
        readonly: false,
        disabled: false,
        outlined: false,
        clearable: true,
        placeholder: '',
      }"
                                                             v-bind:options="{
        inputMask: '(##) #####-####',
        outputMask: '###########',
        empty: null,
        applyAfter: false,
        alphanumeric: false,
        lowerCase: false,
      }" @keyup.native.enter="send" v-on:keyup.enter="send"
                                                             v-bind:focus="focus"
                                                             v-on:focus="focus = false" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="content-buttons">
                    <div class="d-flex justify-content-end">
                        <button v-on:click="send" class="button-primary-bg-primary widht-100 action-button">
                            Avançar
                            <v-icon right>east</v-icon>
                        </button>
                    </div>
                    <div class="d-flex justify-content-end display-sm-none">
                        <div class="fs-13px mt-3 display-sm-none">ou aperte <span class="fw-700">Enter</span></div>
                    </div>
                </div>
            </v-form>
        </div>
    </transition>
</template>

<script>
    import 'bootstrap';

    import { mapState } from "vuex";
    import NotificationService from "../../services/notifications/NotificationService"
    import SimpleMask from "../../components/SimpleMask.vue";
    export default {
        name: 's10-sms',
        props: ['pessoa', 'visible', 'fluxo'],
        components: {
            "v-text-field-simplemask": SimpleMask,
        },
        data: () => ({
            isActive: false,
            loading: false,
            selection: 1,
            step: 1,
            value: "",
            label: "Phone Number",
            focus: false,
            ret: null,
            hasErrors: false,
            rules: {
            },
        }),
        computed: {
            ...mapState(["smscode"]),
            getPessoa() {
                return this.pessoa
            },
        },
        watch: {
            visible: function () {
                this.toggle();
            }
        },
        methods: {
            toggle() {
                this.isActive = !this.isActive;

                if (this.fluxo == 'menor18' || this.fluxo == 'convidadomenor18' || this.fluxo == 'aberturamenor18') {
                    if (this.pessoa.representante !== undefined && this.pessoa.representante.telefone !== undefined && this.pessoa.representante.telefone != null &&
                        (this.pessoa.representante.telefone.tipo !== undefined && this.pessoa.representante.telefone.tipo != null && this.pessoa.representante.telefone.tipo.toString() == "1") &&
                        (this.pessoa.representante.telefone.ddd !== undefined && this.pessoa.representante.telefone.ddd != null) &&
                        (this.pessoa.representante.telefone.numero !== undefined && this.pessoa.representante.telefone.numero != null)
                    ) {
                        this.value = (this.pessoa.representante.telefone.ddd + this.pessoa.representante.telefone.numero.replace("-", "")).toString();
                    }
                }
                else {
                    if (this.pessoa.telefone !== undefined && this.pessoa.telefone != null &&
                        (this.pessoa.telefone.tipo !== undefined && this.pessoa.telefone.tipo != null && this.pessoa.telefone.tipo.toString() == "1") &&
                        (this.pessoa.telefone.ddd !== undefined && this.pessoa.telefone.ddd != null) &&
                        (this.pessoa.telefone.numero !== undefined && this.pessoa.telefone.numero != null)
                    ) {
                        this.value = (this.pessoa.telefone.ddd + this.pessoa.telefone.numero.replace("-", "")).toString();
                    }
                }
            },
            send: function (event) {
                event.preventDefault();


                this.rules = {
                    required: value => !!value || 'Campo obrigatório.',
                    celular: value => {
                        if (value !== undefined && value !== null && value != "") {
                            value = value.replace("-", "");
                            value = value.replace("(", "");
                            value = value.replace(")", "");
                            value = value.replace(" ", "");
                            if (value.length < 11 || isNaN(value)) {
                                return "Número inválido";
                            }
                        }
                        else {
                            return "Número inválido";
                        }
                    },
                };

                this.$nextTick(() => {
                    if (this.$refs.form.validate()) {
                        var v = this.value;
                        if (v !== undefined && v !== null && v != "") {
                            v = v.replace("-", "");
                            v = v.replace(" ", "");

                            if (this.fluxo == 'menor18' || this.fluxo == 'convidadomenor18' || this.fluxo == 'aberturamenor18') {
                                this.pessoa.representante.telefone = {};
                                this.pessoa.representante.telefone.ddi = "55";
                                this.pessoa.representante.telefone.ddd = v.substring(0, 2);
                                this.pessoa.representante.telefone.numero = v.substring(2, 11);
                                this.pessoa.representante.telefone.tipo = "1";
                            }
                            else {
                                this.pessoa.telefone = {};
                                this.pessoa.telefone.ddi = "55";
                                this.pessoa.telefone.ddd = v.substring(0, 2);
                                this.pessoa.telefone.numero = v.substring(2, 11);
                                this.pessoa.telefone.tipo = "1";
                            }
                            var ddi = "55";
                            var ddd = v.substring(0, 2);
                            var numero = v.substring(2, 11);
                            this.$store.commit("saveTelefoneSMS", v);

                            var code = this.generateCode(9) + this.generateCode(9) + this.generateCode(9) + this.generateCode(9) + this.generateCode(9) + this.generateCode(9);
                            this.$store.commit("saveSMSCode", code);

                            NotificationService.envioDoToken(this.fluxo, ddi, ddd, numero, this.pessoa, code).then(() => {
                                //console.log(response);
                                this.next();
                            })
                                .catch(e => {
                                    console.log(e);
                                });
                        }
                    }
                }) 
            },
            generateCode: function (max) {
                return Math.floor(Math.random() * max).toString();
            },
            next: function () {
                this.$emit('next');
            },
            back: function () {
                this.$emit('back');
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

