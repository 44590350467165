<template>
    <transition name="slide-up">
        <div class="content-container content-half" v-if="isActive">
            <div class="flex-column">
                <div class="d-flex">
                    <!--<div class="question-number">11-</div>-->
                    <div class="flex-column padding-div">
                        <div class="fs-32px fw-700 color-dark">Quer cadastrar um beneficiário?</div>
                        <div class="mt-5">
                            <div class="select-boxes-row">
                                <div v-bind:class="(selection == true ? 'select-box select-box-1 active': 'select-box select-box-1')" @click="next">
                                    <p class="mb-0">Sim</p>
                                </div>
                                <div v-bind:class="(selection == false ? 'select-box select-box-2 active' : 'select-box select-box-2')" @click="cadastrarBeneficiarioNao">
                                    <p class="mb-0">Não</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-buttons">
                <div class="d-flex justify-content-end">
                    <button @click="cadastrarBeneficiarioDepois" type="button" class="btn fill-after">
                        Não responder
                    </button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import 'bootstrap';
    export default {
        name: 's09-cadastrar-beneficiario',
        props: ['pessoa', 'visible'],
        data: () => ({
            isActive: false,
            loading: false,
            selection: 1,
            step: 1,
        }),
        watch: {
            visible: function () {
                this.toggle();
            }
        },
        methods: {
            toggle() {
                this.isActive = !this.isActive;
                if (this.isActive) {
                    this.habilitaOpcoes();
                }
            },
            async habilitaOpcoes() {
                if (this.pessoa["indicar-beneficiario"] === undefined) {
                    this.selection = true;
                }
                else {
                    if (this.pessoa["indicar-beneficiario"] == "nao") {
                        this.selection = false;
                    }
                    if (this.pessoa["indicar-beneficiario"] == "depois") {
                        this.selection = null;
                    }
                    else {
                        this.selection = true;
                    }
                }
            },
            next: function () {
                this.pessoa["indicar-beneficiario"] = "sim";
                this.$emit('next');
            },
            back: function () {
                this.$emit('back');
            },
            cadastrarBeneficiarioNao: function () {
                this.pessoa["indicar-beneficiario"] = "nao";
                this.$emit('cadastrarBeneficiarioNao');
            },
            cadastrarBeneficiarioDepois: function () {
                this.pessoa["indicar-beneficiario"] = "depois";
                this.$emit('cadastrarBeneficiarioNao');
            }

        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

