<template>
    <transition name="slide-up">
        <div class="content-container content-half" v-if="isActive">
            <v-form ref="form">
                <div class="flex-column">
                    <div class="d-flex">
                        <!--<div class="question-number">1-</div>-->
                        <div class="flex-column padding-div">
                            <div class="fs-32px fw-700 color-dark">Com qual valor você gostaria de contribuir mensalmente? <span class="fs-18px color-gray no-wrap">(mí­nimo R$ 50,00)</span></div>


                            <!--<div class="single-input">
                                <v-text-field type="text" v-on:keyup.enter="next" placeholder="Digite sua resposta aqui"></v-text-field>
                            </div>-->

                            <div class="single-input">
                                <v-text-field-money v-model="valorContribuicao" required class="input-caps" :rules="[rules.required, rules.contribuicao]" placeholder="Digite sua resposta aqui" @keyup.native.enter="next" v-on:keyup.enter="next"
                                                    v-bind:properties="{
        prefix: 'R$',
        readonly: false,
        disabled: false,
        outlined: false,
        clearable: true,
        placeholder: ' ',
      }"
                                                    v-bind:options="{
        locale: 'pt-BR',
        length: 11,
        precision: 2,
        empty: null,
      }" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-buttons">
                    <div class="d-flex justify-content-end">
                        <button v-on:click="next" class="button-primary-bg-primary action-button">
                            Avançar
                            <v-icon right>east</v-icon>
                        </button>
                    </div>
                    <div class="d-flex justify-content-end display-sm-none">
                        <div class="fs-13px mt-3 display-sm-none">ou aperte <span class="fw-700">Enter</span></div>
                    </div>
                </div>
            </v-form>
        </div>

    </transition>
</template>

<script>
    import 'bootstrap';
    import Decimal from "../../components/Decimal.vue";
    export default {
        name: 's01-contribuicao',
        props: ['pessoa', 'visible'],
        components: {
            "v-text-field-money": Decimal,
        },
        data: () => ({
            isActive: false,
            loading: false,
            selection: 1,
            step: 1,
            valorContribuicao: null, // "0.00",
            hasErrors: false,
            focus: false,
            rules: {
            },
        }),
        watch: {
            visible: function () {
                this.toggle();
            }
        },
        methods: {
            toggle() {
                if (this.pessoa !== null && this.pessoa["perfil-plano"] != null && this.pessoa["perfil-plano"]["valor-contribuicao"] != null) {
                    var v = this.pessoa["perfil-plano"]["valor-contribuicao"]
                    var nv = v.replace('.', '');
                    nv = nv.replace(',', '.');
                    this.valorContribuicao = nv;
                }
                this.isActive = !this.isActive;
            },
            next: function (event) {
                event.preventDefault();

                this.rules = {
                    required: value => !!value || 'Campo obrigatório.',
                    contribuicao: value => {
                        if (value !== undefined && value !== null && value != "") {
                            if (parseFloat(value.replace('.', '').replace(',', '.')) < 50) {
                                return "Contribuição inválida";
                            }
                        }
                        else {
                            return "Contribuição inválida";
                        }
                    },
                };

                this.$nextTick(() => {
                    if (this.$refs.form.validate()) {
                        var v = this.valorContribuicao;
                        var nv = v.replace('.', ',');
                        this.pessoa["perfil-plano"]["valor-contribuicao"] = nv;
                        this.$emit('next');
                    }
                })
            },
            back: function () {
                this.$emit('back');
            }
        },
        created() {

        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
