<template>
    <div class="mb-0 multiselect-row ">
        <span class="letter secondary-text">{{letter}}</span>
        <p class="mb-0">
            <span class="mb-0 secondary-text">{{text}}</span>
        </p>
    </div>
</template>

<script> 
    import 'bootstrap';
    export default {
        name: 'Question',
        props: ['letter','text']


    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

