<template>
    <transition name="slide-up">
        <div class="content-container content-half" v-if="isActive">
            <div class="flex-column">
                <div class="d-flex">
                    <!--<div class="question-number">8-</div>-->
                    <div class="flex-column">
                        <div class="fs-32px fw-700 color-dark">Digite os 6 dígitos que enviamos por SMS (Token)</div>
                        <div class="fs-18px fw-500 color-dark" v-if="fluxo=='menor18' || fluxo=='convidadomenor18' || fluxo=='aberturamenor18'">Solicito, por ato de livre vontade, a inscrição do menor por mim representado, no Plano Instituído Setorial Prevaler, para fins de torná-lo seu participante e confirmo essa operação através de token recebido no telefone celular ou e-mail por mim fornecidos. Responsabilizo-me pela veracidade das informações prestadas durante o processo de adesão online, comprometendo-me a respeitar e observar o Estatuto, Regulamento e normas estabelecidas pela Valia vigentes e aquelas que vierem a ser instituídas, disponíveis no site da Fundação <a href="https://www.valia.com.br" target="_blank" class="valia-link color-primary">(www.valia.com.br)</a>, bem como manter atualizados meus dados ora registrados na Fundação. Declaro ainda que sou o representante legal do menor e que estou disponibilizando seus dados em seu melhor interesse, com objetivo específico de adesão ao Plano PREVALER.</div>
                        <div class="fs-18px fw-500 color-dark" v-else>Solicito, por ato de livre vontade, minha inscrição no Plano Instituído Setorial Prevaler, para fins de tornar-me seu participante e confirmo essa operação através de token recebido no telefone celular ou e-mail por mim fornecidos. Responsabilizo-me pela veracidade das informações prestadas durante o processo de adesão online, comprometendo-me a respeitar e observar o Estatuto. Regulamento e normas estabelecidas pela Valia, vigente e aquelas que vierem a ser instituídas, disponíveis no site da Fundação <a href="https://www.valia.com.br" target="_blank" class="valia-link color-primary">(www.valia.com.br)</a>, bem como manter atualizados meus dados ora registrados na Fundação.</div>
                    </div>
                </div>

                <div class="row mt-5">
                    <div class="col-xl-4 col-lg-6 col-md-8 col-sm-12 m-auto">
                        <v-otp-input length="6" type="number" v-model="otpCode"  @finish="next" ref="otpInput"></v-otp-input>
                        
                        <v-snackbar v-model="snackbar" color="white" :timeout="timeout">
                            <div class="fs-18px fw-500 color-dark">{{ text }}</div>
                        </v-snackbar>
                        <div class="d-flex justify-content-center mt-2">
                            <v-icon class="mr-2  color-primary">cached</v-icon>
                            <a class="valia-link fs-16px  color-primary" @click="send()">
                                Envie novamente
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-buttons">
                <div class="d-flex justify-content-end">
                    <button v-on:click="next" class="button-primary-bg-primary action-button">
                        Concluir Adesão
                        <v-icon right>east</v-icon>
                    </button>
                </div>
                <!--<div class="d-flex justify-content-end display-sm-none">
                    <div class="fs-13px mt-3 display-sm-none">ou aperte <span class="fw-700">Enter</span></div>
                </div>-->
            </div>
        </div>
    </transition>
</template>


<script>
    import 'bootstrap';
    import { mapState } from "vuex"; 
    import NotificationService from "../../services/notifications/NotificationService"
    export default {
        name: 's10-sms-token',
        props: ['pessoa', 'visible', 'fluxo'],
        data: () => ({
            isActive: false,
            loading: false,
            selection: 1,
            step: 1,
            value: null,
            otpCode: null,
            hasErrors: false,
            snackbar: false,
            text: 'Código inválido, tente novamente.',
            timeout: 2000,
        }),
        computed: {
            ...mapState(["smscode"]),
            ...mapState(["telefoneSMS"]),
        },
        watch: {
            visible: function () {
                this.toggle();
            }
        },
        methods: {
            toggle() {
                this.isActive = !this.isActive;
                this.otpCode = null;
                this.snackbar = false;
            },
            send: function () { 
                var code = this.generateCode(9) + this.generateCode(9) + this.generateCode(9) + this.generateCode(9) + this.generateCode(9) + this.generateCode(9);
                this.$store.commit("saveSMSCode", code);
                this.otpCode = null; 
                  
                var v = this.telefoneSMS;
                var ddi = "55";
                var ddd = v.substring(0, 2);
                var numero = v.substring(2, 11);

                //Nao enviar o pessoa, pois nao vamos atualizar o pessoa para o menor e maior
                NotificationService.envioDoToken(this.fluxo, ddi, ddd, numero, this.pessoa, code).then(() => {
                    //console.log(response);
                    this.snackbar = false; 
                })
                    .catch(e => {
                        console.log(e);
                    });
            },
            generateCode: function (max) {
                return Math.floor(Math.random() * max).toString();
            },
            next: function () {
                if (this.smscode == this.otpCode) {
                    this.$emit('next');
                }
                else {
                    this.otpCode = null;
                    this.snackbar = true;
                    return false;
                }
            },
            back: function () {
                this.$emit('back');
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

