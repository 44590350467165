<template>
    <transition name="slide-up">
        <div class="content-container content-half" v-if="isActive">
            <div class="flex-column">
                <div class="d-flex">
                    <!--<div class="question-number">4-</div>-->
                    <div class="flex-column padding-div">
                        <div class="fs-32px fw-700 color-dark">Gostaria de preencher a Análise do Perfil de investimento agora?</div>
                        <div class="fs-22px fw-500 color-gray">
                            Caso não queira escolher agora, você será alocado no
                            <p class="fs-22px valia-tooltip">
                                Perfil Base.
                                <span class="valia-tooltiptext"><span class="fw-600">Perfil de Investimento Base</span> é o menor risco dentre os perfis  oferecidos. As aplicações são feitas apenas em renda fixa e buscam seguir o índice CDI (Certificados de Depósito Interbancário). Existem também os perfis de investimento: <span class="fw-600">Moderado, Multi, Ative</span> e <span class="fw-600">Intense</span>. Você pode escolher qualquer perfil, a qualquer tempo, após preencher o API (Análise de perfil de Investidor). <br />Para saber mais sobre perfil, acesse: <a class="valia-link-white" target="_blank" href="https://www.prevaler.com.br/como-funciona/#investimento">https://www.prevaler.com.br/como-funciona/#investimento</a>.</span>
                            </p>
                        </div>

                        <div class="mt-5">
                            <div class="select-boxes-row">
                                <button v-bind:class="(selection == true ? 'select-box select-box-1 active': 'select-box select-box-1')" @click="next">
                                    <p class="mb-0">Sim</p>
                                </button>
                                <button  type="button" v-bind:class="(selection == false ? 'select-box select-box-2 active' : 'select-box select-box-2')" data-bs-toggle="modal" data-bs-target="#modalDesistirPreenchimentoQuestionario" >
                                    <p class="mb-0">Desejo preencher depois</p>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

 

           
        </div>

    </transition>
</template>
<script>
    import 'bootstrap';
    export default {
        name: 's04-preencheranalise',
        props: ['pessoa', 'visible', 'opcaoanalise'],
        data: () => ({
            isActive: false,
            loading: false,
            //opcaoanalise: null,
            step: 1,

            selection: false,
        }),
        watch: {
            visible: function () {
                this.toggle();
            }
        },
        methods: {
            toggle() {
                this.isActive = !this.isActive;
                if (this.isActive) {
                    this.habilitaOpcoes();
                }
            }, 
            async habilitaOpcoes() {
                if (this.pessoa["perfil-investidor"] === undefined) {
                    this.selection = true;
                }
                else {
                    if (this.pessoa["perfil-investidor"].questao01 == "-1") {
                        this.selection = false;
                    }
                    else {
                        this.selection = true;
                    }
                }
            },
            next: function () {
                this.opcaoanalise = true;
                this.$emit('next');
            },
            back: function () {
                this.$emit('back');
            },
            skipquestionario: function () {
                this.$emit('skipquestionario');
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

