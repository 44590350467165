<template>
    <div>
        <v-text-field v-model="cmpValue"
                      v-bind:label="label"
                      v-bind:rules="rules"
                      v-bind="properties"
                      v-bind:maxlength="options.length + options.precision"
                      v-on:keypress="keyPress"
                      v-on:blur="$emit('blur')"
                      v-on:change="$emit('change')"
                      v-on:click="$emit('click')"
                      v-on:focus="$emit('focus')"
                      v-on:keydown="$emit('keydown')"
                      v-on:mousedown="$emit('mousedown')"
                      v-on:mouseup="$emit('mouseup')"
                      ref="ref"></v-text-field>
    </div>
</template>

<script>
export default {
        model: { prop: "value", event: "input" },
        props: {
            value: {
                type: [String, Number],
                default: "0",
            },
            label: {
                type: String,
                default: "",
            },
            properties: {
                type: Object,
                default: function () {
                    return {};
                },
            },
            rules: {
                type: Array,
                default: function () {
                    return {};
                },
            },
            options: {
                type: Object,
                default: function () {
                    return {
                        locale: "pt-BR",
                        length: 11,
                        precision: 2,
                        empty: null,
                    };
                },
            },
        },
        data: () => ({}),
        /*
         v-model="cmpValue": Dessa forma, ao digitar, o valor � atualizado automaticamente no componente pai.
         O valor digitado entra pelo newValue do Set � emitido para o componente pai, retorna pelo get e p�ra.
        */
        computed: {
            cmpValue: {
                get: function () {
                    return this.humanFormat(this.value);
                },
                set: function (newValue) {
                    this.$emit("input", this.machineFormat(newValue));
                },
            },
        },
        watch: {},
        methods: {
            humanFormat: function (value) {
                if (value || value === 0) {
                    value = Number(value).toLocaleString(this.options.locale, {
                        maximumFractionDigits: this.options.precision,
                        minimumFractionDigits: this.options.precision,
                    });
                } else {
                    value = this.options.empty;
                }
                return value;
            },

            machineFormat(value) {
                if (value) {
                    value = this.clearNumber(value);
                    // Ajustar quantidade de zeros � esquerda
                    value = value.padStart(parseInt(this.options.precision) + 1, "0");
                    // Incluir ponto na casa correta, conforme a precis�o configurada
                    value =
                        value.substring(0, value.length - parseInt(this.options.precision)) +
                        "." +
                        value.substring(
                            value.length - parseInt(this.options.precision),
                            value.length
                        );
                    if (value === "") {
                        value = this.options.empty;
                    }
                } else {
                    value = this.options.empty;
                }
                return value;
            },

            // Retira todos os caracteres n�o num�ricos e zeros � esquerda
            clearNumber: function (value) {
                let result = "";
                if (value) {
                    let flag = false;
                    let arrayValue = value.toString().split("");
                    for (var i = 0; i < arrayValue.length; i++) {
                        if (this.isInteger(arrayValue[i])) {
                            if (!flag) {
                                // Retirar zeros � esquerda
                                if (arrayValue[i] !== "0") {
                                    result = result + arrayValue[i];
                                    flag = true;
                                } else {
                                    // Permitir zero quando valor igual a zero - Tipo 3 (Money or Percent)
                                    if (Number(value) === 0) {
                                        result = result + arrayValue[i];
                                    }
                                }
                            } else {
                                result = result + arrayValue[i];
                            }
                        }
                    }
                }
                return result;
            },

            keyPress($event) {
                // console.log($event.keyCode); //keyCodes value
                let keyCode = $event.keyCode ? $event.keyCode : $event.which;
                // if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                if (keyCode < 48 || keyCode > 57) {
                    // 46 is dot
                    $event.preventDefault();
                }
            },

            isInteger(value) {
                let result = false;
                if (Number.isInteger(parseInt(value))) {
                    result = true;
                }
                return result;
            },

            focus() {
                setTimeout(() => {
                    this.$refs.ref.focus();
                }, 500);
            },

        },
};
</script>
