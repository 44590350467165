<template>
    <transition name="slide-up">
        <div class="content-container content-half multiselect-container" v-if="isActive">
            <div class="content-small">
                <div v-show="isCompleted">
                    <div v-if="!hasErrorsInThisStep">
                        <div class="terminando-img d-flex justify-content-center">
                            <img src="/img/icon-terminando.png" />
                        </div>
                        <div class="content-title">
                            <div class="fs-60px fw-700 color-primary text-center">Parabéns</div>
                            <div class="fs-24px fw-600 color-primary text-center mt-4">Seu cadastro para adesão está concluído.</div>
                        </div>

                        <div v-if="fluxo=='proprio' || fluxo=='propriodpub'" class="content-contents d-flex flex-column align-items-center">
                            <div class="fs-18px fw-600 color-gray-dark text-center mb-3">Agora basta aguardar!</div>
                            <div class="fs-18px fw-600 color-gray-dark text-center mb-3">Você receberá no seu e-mail o status e a confirmação da sua adesão.</div>
                            <div class="fs-18px fw-700 color-dark text-center mb-3">Aproveite agora e faça o Prevaler para seu(s) dependentes (cônjuge, filhos).</div>
                            <a href="https://portal.valia.com.br/" target="_blank" class="btn fill-after action-button">
                                Fazer o Prevaler para<br />meus dependentes
                            </a>
                            <div class="fs-18px fw-700 color-dark text-center mb-5 mt-5">Além disso, indique o Prevaler para seus demais familiares e colegas de trabalho.</div>
                            <a v-bind:href="indique_url" target="_blank" class="button-primary-bg-primary  action-button button-text-white">
                                Indique o Prevaler
                            </a>
                            <a @click="postArquivosTermosAdesao(true, false)" class="mt-4">Visualizar e imprimir termo de adesão</a>
                        </div>
                        <div v-else class="content-contents d-flex flex-column align-items-center">
                            <h5 class="fs-24px fw-600 color-dark text-center mb-5 mt-5">Agora basta aguardar!</h5>
                            <h5 class="fs-24px fw-600 color-dark text-center mb-5 mt-5">Você receberá no seu e-mail o status e a confirmação da sua adesão.</h5>
                            <h5 class="fs-24px fw-700 color-dark text-center mb-5 mt-5">Indique agora o Prevaler para seus demais familiares e ajude-os a tornarem seus sonhos em realidade.</h5>
                            <a v-bind:href="indique_url" target="_blank" class="button-primary-bg-primary action-button button-text-white">
                                Indique o Prevaler
                            </a>
                            <a @click="postArquivosTermosAdesao(true, false)" class="mt-4">Visualizar e imprimir termo de adesão</a>
                        </div>
                        <img v-bind:src="tagURL" width="1" height="1">
                    </div>
                    <div v-else>
                        <div class="content-title">
                            <div class="fs-60px fw-700 color-primary text-center p-5">Desculpe!</div>
                            <div class="fs-24px fw-600 color-dark text-center mb-5 mt-5">Nossos serviços não estão disponíveis no momento.<br /><br />Estamos trabalhando para restaurar todos os serviços o mais rápido possível. Por favor, volte em breve.</div>
                            <div class="d-flex justify-content-center mt-5 p-5">
                                <v-icon class="mr-2  color-primary">cached</v-icon>
                                <a class="valia-link fs-16px  color-primary" @click="setStatusAAndGenerateDocuments()">
                                    Tentar novamente
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import 'bootstrap';
    import PlanoFamiliaAPI from "../../services/PlanoFamiliaAPI";
    import { mapState } from "vuex";
    import Enumerable from "linq";
    import moment from 'moment';
    import Common from "../../services/common/Common";
    import constants from '../../constants';

    export default {
        name: 's11-parabens',
        props: ['pessoa', 'visible', 'savepessoa', 'fluxo'],
        data: () => ({

            indique_url: constants.URL_INDIQUE,
            isActive: false,
            loading: false,
            selection: 1,
            step: 1,
            cpf: null,
            protocolo: null,
            arquivosTermosAdesao: null,
            isCompleted: false,
            hasErrorsInThisStep: false,
            tagURL: null,
        }),
        watch: {
            visible: function () {
                this.toggle();
            }
        },
        computed: {
            ...mapState(["smscode"]),
            ...mapState(["token"]),
            ...mapState(["userIP"]),
        },
        methods: {
            async toggle() {
                this.isActive = !this.isActive;

                if (this.isActive) {
                    this.cpf = this.pessoa.cpf;
                    this.protocolo = this.pessoa.detalhesDaAdesao.protocolo;

                    this.tagURL = 'https://valia.saas.vale.com/pub/cct?_ri_=X0Gzc2X%3DAQjkPkSSUQG2OMi5n1rhOjJza3nUqoyvGW&_ei_=EY8nt1vA7poTZkMsOOGW_PY&action=once&TYPE=PREVALER_ADESAO&OrderTotal=' + this.pessoa["perfil-plano"]["valor-contribuicao"];
                }

                await this.setStatusAAndGenerateDocuments();
            },
            async setStatusAAndGenerateDocuments() {
                var retries = 3;
                var success = false;

                while (retries-- > 0 && !success) {
                    this.pessoa.detalhesDaAdesao.status = "PendenteAnalise";
                    this.pessoa.detalhesDaAdesao.dataFinalizacaoCadastro = moment(new Date(), "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY HH:mm:ss");

                    if (this.fluxo == "aberturamaior18" || this.fluxo == "aberturamenor18") {
                        this.pessoa.detalhesDaAdesao.etapas.push(Common.createEtapa("Etapa 5", this.pessoa.nome, this.pessoa.cpf, this.userIP));
                    }

                    var ret = await Common.putPessoa(this.pessoa.id, this.pessoa);
                    if (ret.success)
                        success = true;
                }

                this.hasErrorsInThisStep = (!success);
                this.isCompleted = true;

                if (!this.hasErrorsInThisStep) {
                    await this.postArquivosTermosAdesao(false, true);
                } 
            }, 
            async postArquivosTermosAdesao(abreDocumento, anexaDocumento) {

                var retries = 3;
                var success = false;

                console.log(anexaDocumento);
                while (retries-- > 0 && !success) {
                    this.arquivosTermosAdesao = await this.carregaArquivosTermosAdesao();

                    if (this.arquivosTermosAdesao.isValid) {
                        if (this.pessoa.anexos === undefined || this.pessoa.anexos == null)
                            this.pessoa.anexos = [];

                        var list = Enumerable.From(this.pessoa.anexos)
                            .Where("$.titulo != 'Termo_Adesao_Plano_Prevaler.pdf' && $.titulo != 'Solicitação de inscrição no plano Prevaler'").ToArray();
                        this.pessoa.anexos = list;

                        var urlArquivo = this.arquivosTermosAdesao.result.urlDownload;
                        urlArquivo = (urlArquivo.indexOf("?sv=") >= 0 ? urlArquivo.substring(0, urlArquivo.indexOf("?sv=")) : urlArquivo);
                        this.pessoa.anexos.push(
                            {
                                "tipo-documento": "Solicitação de inscrição no plano Prevaler",
                                "titulo": this.arquivosTermosAdesao.result.descricao,
                                "url": urlArquivo
                            },
                        );

                        if (abreDocumento)
                            window.open(this.arquivosTermosAdesao.result.urlDownload);

                        this.$emit('savepessoa');
                        success = true;
                    }
                }

                if (!success) {
                    console.log("Houve um erro ao tentar enviar o termo de adesão.")
                }


            },
            async carregaArquivosTermosAdesao() {
                return await PlanoFamiliaAPI.postArquivosTermosAdesao(this.cpf, this.protocolo, this.smscode, this.userIP)
                    .then(response => {
                        return response.data;
                    })
                    .catch(e => {
                        console.log(e);
                        var r = {
                            "isValid": false
                        };
                        return r;
                    });
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

