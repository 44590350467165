<template>
    <transition name="slide-up">
        <div v-if="isActive">
            <div class="content-container content-half multiselect-container">
                <div class="flex-column mt-container">
                    <div class="d-flex">
                        <div class="question-number">3-</div>
                        <div class="flex-column w-100">
                            <div class="fs-32px fw-700 color-dark">Qual das frases abaixo melhor se aplica a você em relação aos recursos investidos para o objetivo definido em questão?</div>

                            <div class="mt-5">
                                <div class="multiselect-group">
                                    <div class="multiselect-row pointer" @click="select(1)">
                                        <div v-bind:class="(selection == 1 ? 'multiselect active' : 'multiselect')">
                                            <Question letter="A" text="Avalio constantemente os resultados, e me preocupo com oscilações negativas"></Question>
                                        </div>
                                    </div>
                                    <div class="multiselect-row pointer" @click="select(2)">
                                        <div v-bind:class="(selection == 2 ? 'multiselect active' : 'multiselect')">
                                            <Question letter="B" text="Não penso nesses recursos no curto prazo, mas rentabilidades mensais baixas me incomodam"></Question>
                                        </div>
                                    </div>
                                    <div class="multiselect-row pointer" @click="select(3)">
                                        <div v-bind:class="(selection == 3 ? 'multiselect active' : 'multiselect')">
                                            <Question letter="C" text="Avalio o que está acontecendo, mas mantenho foco no longo prazo"></Question>
                                        </div>
                                    </div>
                                    <div class="multiselect-row pointer" @click="select(4)">
                                        <div v-bind:class="(selection == 4 ? 'multiselect active' : 'multiselect')">
                                            <Question letter="D" text="Não me preocupo com eles, uma vez que só vou utilizar tais recursos daqui a muitos anos"></Question>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="content-buttons">
                                <button type="button" class="btn fill-after" data-bs-toggle="modal" data-bs-target="#modalDesistirPreenchimentoQuestionario">
                                    Preencher depois no Portal do Participante
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import 'bootstrap';
    import { mapState } from "vuex";
    import Question from '../../components/Question.vue';
    export default {
        name: 's04-preencheranalise-3',
        props: ['pessoa', 'visible'],
        components: {
            Question
        },
        computed: {
            ...mapState(["questionario"]),
        },
        data: () => ({
            isActive: false,
            loading: false,
            selection: 0,
        }),
        created() {
            this.selection = this.questionario.questao03;
        },
        watch: {
            visible: function () {
                this.toggle();
            }
        },
        methods: {
            toggle() {
                this.isActive = !this.isActive;
            }, 
            select: function (val) {
                this.selection = val;
                this.$emit('selectquiz', null, '03', val);
                this.next();
            },
            next: function () {
                this.$emit('next');
            },
            back: function () {
                this.$emit('back');
            },
            skipquestionario: function () {
                this.$emit('skipquestionario');
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

